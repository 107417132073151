<template>
	<div>
		<contentHeader title="Penjualan Langsung" />
		<LoadingScreen v-if="loading_screen" />
		<div class="content-header">
			<div class="container-fluid">
				<div class="row justify-content-center">
					<div class="col">
						<div class="card">
							<div class="card-header row d-flex align-items-center">
          			<h3 class="card-title col-6">Penjualan Langsung</h3>
          			<div class="col-6 text-right">
          				<router-link to="/tambah-penjualan-langsung" class="btn btn-info btn-sm">Tambah Obat Baru</router-link>			
          			</div>
          		</div>
          		<div class="card-body">
          			<div class="row align-items-center">
          				<div class="col-lg-2">
          					<span>Jumlah Baris</span>
          					<select class="custom-select form-control" v-model="page" @change="changePage">
            					<option value="10">10</option>
            					<option value="15">15</option>
            					<option value="20">20</option>
            					<option value="25">25</option>
            					<option value="30">30</option>
            					<option value="40">40</option>
            					<option value="45">45</option>
            					<option value="50">50</option>
            				</select>
          				</div>
                        <div>

                        </div>
                      <div class="col-lg-4">
                         <span>Pasien</span>
                         <input type="text" class="form-control" v-model="kata_kunci" @keyup="getDataResult">
                      </div>
		              <div class="col-lg-5">
		              </div>
          			</div>
          			<table class="table table-bordered table-hover mt-4">
	                  <thead>
	                    <tr>
	                      <th scope="col" class="text-sm">TANGGAL</th>
	                      <th scope="col" class="text-sm">NOMOR FAKTUR</th>
	                      <th scope="col" class="text-sm">NAMA PENJUAL</th>
	                      <th scope="col" class="text-sm">TOTAL</th>
	                      <th scope="col" class="text-sm">STATUS</th>
	                      <th scope="col" class="text-sm">ACTION</th>
	                    </tr>
	                  </thead>
	                  <tbody>
	                  	<tr v-for="row in data_result" :key="row.id">
	                  		<td>{{ row.created_at.slice(0, 10) }}</td>
	                  		<td>{{ row.nomor_faktur }}</td>
	                  		<td>{{ row.nama_pembeli }}</td>
	                  		<td>{{ format_nominal(row.total) }}</td>
	                  		<td>{{ row.status.toUpperCase() }}</td>
	                  		<td class="d-flex justify-content-center">
	                  			<button class="btn btn-danger btn-sm text-white text-sm" v-if="row.status.toLowerCase() == 'pending'">BATAL</button>
	                  			<button class="btn btn-info btn-sm text-white text-sm ml-2" data-toggle="modal" data-target="#detail_pemesanan" @click="detailPemesanan(row.id)">DETAIL</button>
	                  		</td>
	                  	</tr>
	                  </tbody>
	                </table>
	                <p class="text-center mt-2" v-if="memuat_data">Memuat Data</p>
	                <p class="text-center mt-2" v-if="cek_data">Data Masih Kosong</p>
          		</div>
          		<div class="card-footer">
								<nav aria-label="...">
								  <ul class="pagination">
								    <li class="page-item" :class="{ disabled: pagination.current_page == pagination.from}" @click="paginateData(pagination.prev_page_url)">
								      <a class="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a>
								    </li>
								    <li class="page-item" :class="{ active: pagination.current_page == row.label }" @click="paginateData(row.url)" v-for="(row, index) in links" :key="index"><a class="page-link" href="#">{{ row.label }}</a></li>
								    <li class="page-item" :class="{ disabled: pagination.current_page == pagination.last_page}" @click="paginateData(pagination.next_page_url)">
								      <a class="page-link" href="#">Next</a>
								    </li>
								  </ul>
								</nav>
		          </div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- MODAL -->
		<div class="modal" tabindex="-1" role="dialog" id="detail_pemesanan">
		  <div class="modal-dialog modal-lg" role="document">
		    <div class="modal-content">
		      <div class="modal-header">
		        <h5 class="modal-title">Detail Pemesanan</h5>
		        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
		          <span aria-hidden="true">&times;</span>
		        </button>
		      </div>
		      <div class="modal-body">
		      	<table class="table table-bordered table-hover" v-if="!memuat_detail_pemesanan">
                <thead>
                  <tr>
                  	<th scope="col" class="text-sm">NO</th>
                    <th scope="col" class="text-sm">NAMA</th>
                    <th scope="col" class="text-sm">HARGA</th>
                    <th scope="col" class="text-sm">JUMLAH</th>
                    <th scope="col" class="text-sm">TOTAL</th>
                  </tr>
                </thead>
                <tbody>
                	<tr v-for="(row, index) in detail_pemesanan" :key="row.id">
                		<td>{{ index + 1 }}</td>
                		<td>{{ row.produk.nama }}</td>
                		<td>{{ format_nominal(row.harga_beli) }}</td>
                		<td>{{ row.jumlah }}</td>
                		<td>{{ format_nominal(row.total) }}</td>
                	</tr>
                </tbody>
              </table>
		        <p class="text-center" v-if="memuat_detail_pemesanan">Memuat Data...</p>
		      </div>
		      <div class="modal-footer">
		        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
		      </div>
		    </div>
		  </div>
		</div>
		<!-- END MODAL -->

	</div>
</template>

<script>
	import ContentHeader from '@/components/ContentHeader'
	import { computed, onMounted, ref } from 'vue'
	import store from '@/store'
	import axios from 'axios'
	import format_nominal from '@/format_nominal'
	import LoadingScreen from '@/components/LoadingScreen'
	import format_tanggal from '@/format_tanggal'

	export default{
		components: {
			ContentHeader: ContentHeader,
			LoadingScreen: LoadingScreen
		},
		setup () {
			const user = computed(() => store.getters['auth/user'])
			const cabang = computed(() => store.getters['auth/user'])
			
			// Show Data + Pagination 
			const { data_result, memuat_data, links, paginateData, pagination, getDataResult, page, cek_data, changePage, loading_screen, periode_awal, periode_akhir } = useShowData(user)

			// Detail Pemesanan
			const { detailPemesanan, memuat_detail_pemesanan, detail_pemesanan } = useAction(cabang.value)

			onMounted(() => {
				getDataResult()
			})

			return { data_result, memuat_data, links, paginateData, pagination, getDataResult, page, cek_data, format_tanggal, format_nominal, changePage, loading_screen, detailPemesanan, memuat_detail_pemesanan, detail_pemesanan, periode_awal, periode_akhir }
		}
	}

	function useShowData (user) {
		const data_result = ref([])
		const memuat_data = ref(true)
		const cek_data = ref(false)
		const page = ref(10)
		const links = ref([])
		const pagination = ref([])
		const loading_screen = ref(false)
    const periode_awal = ref('')
    const periode_akhir = ref('')

		const getDataResult = async () => {
			data_result.value = []
    	memuat_data.value = true
    	loading_screen.value = true
    	cek_data.value = false

    	let tanggal_awal = ''
      let tanggal_akhir = ''

    	if (periode_awal.value == '' && periode_akhir.value == '') {
      	tanggal_awal = 'emptyParameter'
      	tanggal_akhir = 'emptyParameter'
      } else {
      	tanggal_awal = periode_awal.value
      	tanggal_akhir = periode_akhir.value
      }

			let { data } = await axios.get(`api/pembelian/getPenjualanLangsung/${user.value.cabang_id}/${page.value}/${tanggal_awal}/${tanggal_akhir}`)

			if (data != 'kosong') {
				memuat_data.value = false
				cek_data.value = false
				data_result.value = data.data
				links.value = data.links
  			links.value.splice(0, 1)
  			links.value.splice(links.value.length-1, 1)
  			pagination.value = data
    		loading_screen.value = false
			} else {
				cek_data.value = true
				memuat_data.value = false
				data_result.value = []
				loading_screen.value = false
			}
		}

		const changePage = () => {
			getDataResult()
		}

		const paginateData = async (url) => {
  		if (url != null) {
  			data_result.value = []
    		memuat_data.value = true

    		let link_url = new URL(url)
    		link_url = `${link_url.pathname}${link_url.search}`
    		
    		let { data } = await axios.get(`${link_url}`)

    		
    		if (data != 'kosong') {
    			memuat_data.value = false
    			data_result.value = data.data	
    			links.value = data.links
    			links.value.splice(0, 1)
    			links.value.splice(links.value.length-1, 1)
    			pagination.value = data
    		}
  		}
  	}

  	return { data_result, memuat_data, links, paginateData, pagination, getDataResult, page, cek_data, format_tanggal, format_nominal, changePage, loading_screen, periode_awal, periode_akhir }
	}

	function useAction (cabang) {
		const detail_pemesanan = ref([])
		const memuat_detail_pemesanan = ref(false)

		const detailPemesanan = async (id) => {
			memuat_detail_pemesanan.value = true
			let { data } = await axios.get(`api/pembelian/getDetail/${id}/${cabang.tipe}`)

			if (data == 'kosong') {
				memuat_detail_pemesanan.value = false
				detail_pemesanan.value = []
			} else {
				memuat_detail_pemesanan.value = false
				detail_pemesanan.value = data 
			}
		}

		return { detailPemesanan, memuat_detail_pemesanan, detail_pemesanan }
	}
</script>